export const GET_ADVERTS = 'GET_ADVERTS';
export const GET_LAST_POSTS = 'GET_LAST_POSTS';
export const GET_LOST = 'GET_LOST';
export const DELETE_LOST = 'GET_LOST';
export const EDIT_LOST = 'GET_LOST';
export const GET_LOST1 = 'GET_LOST1';
export const GET_FOUND = 'GET_FOUND';
export const GET_FILTERED = 'GET_FILTERED';
export const GET_PARAMS = 'GET_PARAMS';
export const GET_PROFILE = 'GET_PROFILE';
export const DELETE_PROFILEIMG = 'GET_PROFILEIMG';
export const EDIT_PROFILEIMG = 'EDIT_PROFILEIMG';
export const DELETE_PROFILEPOST = 'DELETE_PROFILEPOST';
export const MAKE_LIKE = 'MAKE_LIKE';
export const DELETE_LIKE = 'DELETE_LIKE';
export const GET_LIKES = 'GET_LIKES';
export const GET_LABEL = 'GET_LABEL';
export const SEND_MESSAGE = 'SEND_MESSAGE';
